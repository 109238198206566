import useTranslation from 'next-translate/useTranslation';

import CustomSelect from '@/components/Select/CustomSelect';

const PracticeSelector = ({ isDisabled, onChange, value, ...props }) => {
    const { t } = useTranslation();

    const practices = [
        {
            value: 'DENTIST',
            label: t('common:dentist')
        },
        {
            value: 'OSTEOPATH',
            label: t('common:osteopath')
        },
        {
            value: 'GENERALPRACTITIONER',
            label: t('common:generalist')
        },
        {
            value: 'GYNECOLOGIST',
            label: t('common:gynecologist')
        },
        {
            value: 'OPHTHALMOLOGIST',
            label: t('common:ophthalmologist')
        },
        {
            value: 'PEDIATRICIAN',
            label: t('common:pediatrician')
        },
        {
            value: 'PSYCHOLOGIST',
            label: t('common:psychologist')
        },
        {
            value: 'DERMATOLOGIST',
            label: t('common:dermatologist')
        },
        {
            value: 'PSYCHIATRIST',
            label: t('common:psychiatrist')
        },
        {
            value: 'PHYSIOTHERAPIST',
            label: t('common:physiotherapist')
        }
    ];

    return (
        <CustomSelect
            name="practice selector"
            label={t('common:chooseSpecialty')}
            options={practices}
            placeholder={t('common:selectOption')}
            isDisabled={isDisabled}
            onChange={onChange}
            value={value}
            {...props}
        />
    );
};

export default PracticeSelector;
