/* eslint-disable react/display-name */
import { Button, HStack, IconButton, Text, useTheme, VStack } from '@chakra-ui/react';
import Head from 'next/head';
import Image from 'next/image';
import { useRouter } from 'next/router';
import setLanguage from 'next-translate/setLanguage';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { FiUser } from 'react-icons/fi';
import { useDispatch } from 'react-redux';

import InfiniteImageScroll from '@/components/Images/InfiniteImageScroll';
import Form from '@/components/Inputs/Form';
import { getLayout as getSiteLayout } from '@/components/Layout/SiteLayout';
import LanguageSelect from '@/components/Select/LanguageSelect';
import LocationSelector from '@/components/Select/LocationSelector';
import PracticeSelector from '@/components/Select/PracticeSelector';
import { loadNamespaces } from '@/pages/_app';
import * as gtag from '@/utils/gtag';

const Entry = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t, lang } = useTranslation();
    const [practice, setPractice] = useState(null);
    const [place, setPlace] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const currentLanguage = lang.split('_')[0];
    const router = useRouter();

    const handleSearch = (e) => {
        setIsLoading(true);
        e.preventDefault();

        gtag.setVar({
            search_practice: practice.value.toLowerCase(),
            search_region: place.value['region'].toLowerCase(),
            search_province: place.value['province-en'].toLowerCase(),
            search_location: place.value['en'].toLowerCase(),
            search_postcode: place.value.postcode
        });

        gtag.customEvent('search_basic');

        dispatch({
            type: 'SET_SEARCH_PARAMETERS',
            lat: parseFloat(place.value.lat),
            long: parseFloat(place.value.long),
            practice,
            place
        });
        const pathname = `/${practice.label.toLowerCase()}/${place.value[
            `province-${currentLanguage}`
        ]
            .split(' ')
            .join('-')
            .toLowerCase()}/${place.value[currentLanguage].toLowerCase()}/${place.value.postcode}`;

        router.push({
            pathname,
            query: {
                lat: place.value.lat,
                long: place.value.long
            }
        });
    };

    const changeLanguage = async (lang) => {
        await setLanguage(lang.value);
    };

    return (
        <>
            <Head>
                <title>{`Tooddoc ${t('common:tooddocSubtitle')}`}</title>
                <meta content={`Tooddoc ${t('common:tooddocSubtitle')}`} name="description" />
            </Head>
            <HStack mt="0.5rem" mr="0.5rem" mb={['2rem', '3rem']} alignSelf="flex-end">
                <LanguageSelect minWidth="8rem" onChange={changeLanguage} value={currentLanguage} />
                <IconButton
                    aria-label="Menu button"
                    backgroundColor="white"
                    borderColor="gray.200"
                    borderRadius="lg"
                    borderWidth="2px"
                    fontSize="xl"
                    icon={<FiUser />}
                    onClick={() => router.push('/profile')}
                    shadow="lg"
                    size="md"
                />
            </HStack>
            <VStack
                as="main"
                height="100%"
                justify="center"
                margin="0 auto"
                mb="7rem"
                p="1rem"
                theme={theme}
                w={['100%', '70%', '50%']}>
                <Image
                    alt="Tooddoc Logo"
                    as="img"
                    mb="1rem"
                    height="100px"
                    width="80px"
                    objectFit="contain"
                    src="/images/tood.svg"
                />

                <Text as="h1" color="tooddoc.500" fontSize="4xl" fontWeight="bold">
                    Tooddoc
                </Text>

                <Text
                    as="h2"
                    color="tooddoc.700"
                    fontSize="2xl"
                    fontWeight="bold"
                    textAlign="center"
                    mb="3rem">
                    {t('common:tooddocSubtitle')}
                </Text>

                <Form align="center" onSubmit={handleSearch}>
                    <PracticeSelector
                        height="100%"
                        inputHeight="3rem"
                        mb="1rem"
                        onChange={setPractice}
                        width="100%"
                    />
                    <LocationSelector
                        height="100%"
                        inputHeight="3rem"
                        mb="1rem"
                        onPlaceSelect={(place) => {
                            if (place) {
                                setPlace(place);
                            } else {
                                setPlace(null);
                            }
                        }}
                        width="100%"
                    />

                    <Text
                        as="h3"
                        color="gray.400"
                        fontSize="xs"
                        fontWeight="bold"
                        textAlign="center"
                        mb="2rem">
                        {t('common:toddocStats')}
                    </Text>

                    <Button
                        aria-label={t('common:searchButton')}
                        borderRadius="lg"
                        disabled={!place || !practice}
                        isLoading={isLoading}
                        h="3rem"
                        leftIcon={<FiSearch />}
                        onClick={handleSearch}
                        shadow="md"
                        type="submit"
                        colorScheme="blue"
                        w={['100%', '50%', '40%', '30%']}>
                        {t('common:search')}
                    </Button>
                </Form>
            </VStack>
            <Text
                style={{ contentVisibility: 'auto' }}
                textAlign="center"
                fontSize="2xl"
                fontWeight="bold"
                mb="1rem"
                color="gray.500">
                Nos partenaires
            </Text>
            <InfiniteImageScroll
                style={{ contentVisibility: 'auto' }}
                align="center"
                justify="center"
                wrap="wrap"
                mb="4rem"
            />
        </>
    );
};

export async function getStaticProps({ locale }) {
    return {
        props: {
            _ns: await loadNamespaces(['common'], locale)
        }
    };
}

export const getLayout = (page) => getSiteLayout(<Entry>{page}</Entry>);

export default Entry;
