import useTranslation from 'next-translate/useTranslation';

import CustomSelect from '@/components/Select/CustomSelect';

const LanguageSelect = ({ onChange, value, ...props }) => {
    const { t } = useTranslation();

    const languages = [
        { value: 'fr', label: t('common:french') },
        { value: 'en', label: t('common:english') },
        { value: 'nl', label: t('common:dutch') }
    ];

    const correctValue = languages.find((lang) => lang.value === value);

    return (
        <CustomSelect
            name="inami status selector"
            options={languages}
            placeholder={t('common:selectStatus')}
            onChange={onChange}
            value={correctValue}
            {...props}
        />
    );
};

export default LanguageSelect;
