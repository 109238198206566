import { Image, Stack } from '@chakra-ui/react';

const partners = [
    { image: 'partenamut', link: 'https://www.partenamut.be/fr/' },
    { image: 'cbc', link: 'https://www.cbc.be/particuliers/fr.html' },
    { image: 'ophtalmologia', link: 'https://www.ophthalmologia.be/page.php?edi_id=1' },
    { image: 'osteopathe', link: 'https://osteopathie.be/fr/accueil/' },
    { image: 'smd', link: 'https://www.dentiste.be' },
    { image: 'partena', link: 'https://www.partena-ziekenfonds.be/nl' },
    { image: 'freiekrankenkasse', link: 'https://www.freie.be' }
];

const InfiniteImageScroll = ({ ...props }) => {
    return (
        <Stack overflowX="auto" direction={['column', 'row']} {...props}>
            {partners.map((partner) => {
                return (
                    <a key={partner.link} href={partner.link} target="_blank" rel="noreferrer">
                        <Image
                            alt={partner.image}
                            as="img"
                            height="2.5rem"
                            objectFit="contain"
                            loading="lazy"
                            src={`/images/${partner.image}.webp`}
                        />
                    </a>
                );
            })}
        </Stack>
    );
};

export default InfiniteImageScroll;
