import { useTheme } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import AsyncSelect from 'react-select/async';

import InputField from '@/components/Inputs/InputField';
import locations from '@/utils/locations';

const LocationSelector = ({ isDisabled, isRtl, onPlaceSelect, value, ...props }) => {
    const { t } = useTranslation();

    const displaySuggestions = (inputValue) => {
        if (inputValue && inputValue.trim() !== '') {
            const value = inputValue.toString().toLowerCase();

            return new Promise((resolve) => {
                const remapped = locations
                    .filter((location) => {
                        if (
                            location['province-en'].toLowerCase().includes(value) ||
                            location.postcode?.toString().toLowerCase().includes(value)
                        ) {
                            location.selectOption = `${location.postcode} ${location.en} ${location['province-en']}`;

                            return true;
                        } else if (location.en.toLowerCase().includes(value)) {
                            location.selectOption = `${location.postcode} ${location.en} ${location['province-en']}`;

                            return true;
                        } else if (location.fr.toLowerCase().includes(value)) {
                            location.selectOption = `${location.postcode} ${location.fr} ${location['province-fr']}`;

                            return true;
                        } else if (location.nl.toLowerCase().includes(value)) {
                            location.selectOption = `${location.postcode} ${location.nl} ${location['province-nl']}`;

                            return true;
                        } else if (location.de.toLowerCase().includes(value)) {
                            location.selectOption = `${location.postcode} ${location.de} ${location['province-de']}`;

                            return true;
                        }

                        return false;
                    })
                    .map((location) => ({
                        value: location,
                        label: location.selectOption
                    }));

                resolve(remapped);
            });
        }
    };

    const theme = useTheme();

    return (
        <InputField
            label={`${t('common:enter')} ${t('common:your')} ${t('common:locality')}`}
            name="location selector"
            type="text"
            {...props}>
            <AsyncSelect
                height={props.height}
                inputId="location selector"
                instanceId="location-select"
                isClearable
                isDisabled={isDisabled}
                isRtl={isRtl}
                loadOptions={displaySuggestions}
                onChange={onPlaceSelect}
                placeholder={t('common:localityExample')}
                value={value}
                styles={{
                    indicatorSeparator: (provided) => ({
                        ...provided,
                        width: 0
                    }),
                    placeholder: (provided) => {
                        return {
                            ...provided,
                            fontWeight: 'bold',
                            color: theme.colors.gray[400]
                        };
                    },
                    control: (provided) => {
                        return {
                            ...provided,
                            borderRadius: theme.radii.lg,
                            height: props.inputHeight
                        };
                    },
                    dropdownIndicator: (provided) => ({
                        ...provided,
                        width: 0
                    }),
                    singleValue: (provided) => {
                        return {
                            ...provided,
                            color: theme.colors.tooddoc[600],
                            fontWeight: 600
                        };
                    }
                }}
                textFieldProps={{
                    label: 'Label',
                    InputLabelProps: {
                        shrink: true
                    }
                }}
            />
        </InputField>
    );
};

export default LocationSelector;
